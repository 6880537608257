<template>
  <div>
    <form
      @submit.prevent="submitHandler"
      v-form-enter
      v-if="!isLoading"
    >
      <div
        class="p-fluid formgrid grid pt-3"
        style="background-color: #f2f4f6; border-radius: 12px"
      >
        <div class="col-4 md:col-3 sm:col-12">
          <div align="center">
            <img
              v-if="user.profile_picture"
              :src="user.profile_picture"
              class="profile-preview"
              style="width: 70px; height: 70px"
            />
            <i
              v-else
              class="la la-user no-profile"
            />
          </div>
        </div>
        <div class="col-8 md:col-9 sm:col-12 formgrid grid flex">
          <div class="field col-12 sm:col-6 flex align-self-end">
            <div class="flex flex-column">
              <span>{{ user.name }} {{ user.surname }}</span>
              <span class="font-light">{{ user.title }}</span>
            </div>
          </div>

          <div class="field col-12 sm:col-6 flex align-self-end">
            <div class="flex flex-column">
              <span>Contact Number:</span>
              <span class="font-light">{{ user.contact_number }}</span>
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <div class="flex flex-column">
              <span>Email:</span>
              <span class="font-light">{{ user.email }}</span>
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <div class="p-inputgroup flex align-items-center">
              <AActiveSwitch v-model="user.active" />

              <label
                class="ml-3 mt-1"
                for="billable"
              >Account Active</label>
            </div>
          </div>
        </div>
      </div>
      <div class="field col-12 formgrid grid">
        <TabView
          class="p-tab-show-panel mb-3"
          v-bind:activeIndex="activeIndex"
          @tab-change="handleTabChange"
        >
          <TabPanel header="Profile">
            <div class="formgrid grid">
              <div class="field col-12 sm:col-6">
                <label class="label">Department</label>

                <div class="p-inputgroup">
                  <MultiSelect
                    v-model="user.departments"
                    style="max-width: 300px;"
                    :options="rolesDepartment"
                    dataKey="uid"
                    optionLabel="department_name"
                    placeholder="Department"
                    display="chip"
                  />
                </div>
              </div>
              <div class="field col-12 sm:col-6 flex align-items-end">
                <div class="p-inputgroup flex align-items-center">
                  <AActiveSwitch v-model="user.contract" />

                  <label
                    class="ml-3 mt-1 hidden sm:block"
                    for="billable"
                  >Is a freelancer</label>
                </div>
              </div>
              <div class="field col-12 sm:col-6">
                <label class="label">Rate/Hour</label>

                <div class="p-inputgroup">
                  <InputText
                    placeholder="Rate/Hour"
                    v-model="user.rate_hour"
                  />
                </div>
              </div>

              <div class="field col-12 sm:col-6">
                <label class="label">Overtime Rate/Hour</label>

                <div class="p-inputgroup">
                  <InputText
                    placeholder="Overtime Rate/Hour"
                    v-model="user.rate_overtime"
                  />
                </div>
              </div>
              <div class="field col-12 sm:col-6">
                <label class="label">Permissions</label>

                <div class="p-inputgroup">
                  <Dropdown
                    :options="permissions"
                    optionLabel="name"
                    optionValue="permission_id"
                    v-model="user.permission_id"
                    placeholder="Permissions"
                  />
                </div>
                <!-- <div>
                  <p
                    class="cursor-pointer"
                    @click="
                      $store.dispatch('setSliderView', 'PermissionsSlider')
                    "
                  >
                    Customize
                  </p>
                </div> -->
              </div>
              <div class="field col-12 sm:col-6 flex align-items-end">
                <div class="p-inputgroup flex align-items-center">
                  <AActiveSwitch v-model="user.is_reviewer" />

                  <label
                    class="ml-3 mt-1 hidden sm:block"
                    for="billable"
                  >Can Review Work</label>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Working Hours">
            <div class="formgrid grid">
              <template v-for="(day, id) in user.working_hours">
                <div
                  :key="id + 'a'"
                  class="col-6 field"
                >
                  <div class="p-inputgroup align-items-center justify-content-between pr-3">
                    <span> {{ day.label }} </span>
                    <InputSwitch
                      v-model="day.on"
                      id="d"
                      class="green"
                    />
                  </div>
                </div>
                <div
                  :key="id + 'b'"
                  class="col-6 field"
                >
                  <div class="p-inputgroup align-items-center">
                    <InputMask
                      v-if="day.on"
                      v-model="day.hours"
                      mask="99:99"
                    />
                    <span
                      v-if="day.on"
                      class="pl-3 hidden sm:block"
                    >hrs/day</span>
                  </div>
                </div>
              </template>
            </div>
          </TabPanel>
        </TabView>
      </div>

      <div class="flex justify-content-between">
        <Button type="submit"> SAVE </Button>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>
    <RocketLoad
      :full="true"
      v-else
    />
  </div>
</template>
<script>
import store from "@/store";
import vueFilePond, { setOptions } from "vue-filepond";
setOptions({
  server: {
    url: process.env.VUE_APP_UPLOAD_URL,
    timeout: 7000,
    process: {
      url: "/v1/users/upload",
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.token,
      },
    },
  },
});

import {
  state,
  fetchRolesDepartment,
  fetchAccountRoles,
} from "../../../services/data_service.js";

export default {
  name: "UserSliderDetails",

  data() {
    return {
      tabs: ["profile", "workingHours"],
      activeIndex: 0,
      formOptions: [],
      spinnerLoading: false,
      myFiles: [],
      name: "",
      email: "",
      // primary_role: null,
      // secondary_role: null,
      profilePicture: "",
      user: null,
      permissions: [
        { permission_id: 1, name: "Admin" },
        { permission_id: 2, name: "User" },
      ],
      isLoading: true,
      loadingText: "Loading...",
      inputIsVisible: false,
      contracts: [
        { name: "Inhouse", code: 0 },
        { name: "Freelancer", code: 1 },
      ],
      reviewer: [
        { name: "Yes", code: 1 },
        { name: "No", code: 0 },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    fetchRolesDepartment();
    fetchAccountRoles();
    this.getUser();
  },
  methods: {
    handleTabChange(ev) {
      this.activeIndex = ev.index;
      const newHash = "#" + ev.index.toString();
      history.pushState(null, null, newHash);
    },

    // getRoles() {
    //   if (!this.user.primary_department.uid) {
    //     return [];
    //   }
    //   var department = this.rolesDepartment.find(
    //     (a) => a.uid === this.user.primary_department.uid
    //   );
    //   var arr = [];
    //   if (!department.roles.find((r) => r.role.uid == this.primary_role)) {
    //     this.primary_role = null;
    //   }
    //   department.roles.forEach((a) => {
    //     arr.push(a.role);
    //   });
    //   return arr;
    // },

    getUser() {
      this.$axios
        .get(process.env.VUE_APP_ROOT_API + "/v1/users/" + this.user_uid, {})
        .then((response) => {
          this.user = response.data.data;

          // for (let index = 0; index < state.rolesDepartment.length; index++) {
          //   if (this.user.primary_department_uid == state.rolesDepartment[index].uid) {
          //     this.rolesDepartment = state.rolesDepartment[index];
          //   }
          // }

          if (!this.user.working_hours) {
            this.user.working_hours = {
              mon: { on: true, hours: "06:00", label: "Monday" },
              tue: { on: true, hours: "06:00", label: "Tuesday" },
              wed: { on: true, hours: "06:00", label: "Wednesday" },
              thu: { on: true, hours: "06:00", label: "Thursday" },
              fri: { on: true, hours: "06:00", label: "Friday" },
              sat: { on: false, hours: "00:00", label: "Saturday" },
              sun: { on: false, hours: "00:00", label: "Sunday" },
            };
          }
          // this.primary_role = this.user.primary_role.uid;
          // this.secondary_role = this.user.secondary_role.uid;
          if (this.user.email) {
            this.user.email = this.user.email.email;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    submitHandler() {
      this.loadingText = "Processing...";
      // this.user.primary_role = this.primary_role;
      // this.user.secondary_role = this.secondary_role;
      this.user.birthdate = this.$moment(this.user.birthdate).format(
        "YYYY-MM-DD"
      );

      this.isLoading = true;
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/users/" + this.user_uid,
          this.user,
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.clearSliderContent();
          this.$toast.add({
            severity: "success",
            summary: "User Saved",
            detail: this.user.name + " has been saved",
            life: 3000,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    saveUserStatus() {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/users/status/" + this.user_uid,
          this.user,
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.$toast.add({
            severity: "success",
            summary: "User Status Updated",
            life: 3000,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
  },
  computed: {
    user_uid() {
      return this.$store.getters.selectedObject;
    },
    rolesDepartment() {
      return state.rolesDepartment;
    },
    accountRoles() {
      return state.accountRoles;
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.profile-preview {
  height: 124px;
  width: 124px;
  border-radius: 100%;
  object-fit: cover;
}

.no-profile {
  font-size: 124px;
  background-color: #b9c5d0;
  color: #f2f4f6;
  border-radius: 100%;
}
/* error state color */
[data-filepond-item-state*="error"] .filepond--item-panel,
[data-filepond-item-state*="invalid"] .filepond--item-panel {
  background-color: #fe4d97;
}

[data-filepond-item-state="processing-complete"] .filepond--item-panel {
  background-color: #6dd230;
}

.floating-profile-edit {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 110px;
}
</style>